
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .nav-items {
    @apply text-black font-normal text-base duration-300 ease-linear hover:text-light-orange;
  }

  .nav-toggle-items {
    @apply w-10 h-1 rounded-xl bg-black block;
  }
  .quote-input {
    @apply py-2.5 px-[18px] bg-white outline-none font-normal text-base leading-125 text-dark-blue text-opacity-70 w-full;
  }
  .info-input {
    @apply quote-input sm:h-10 h-8 !text-sm font-medium !opacity-100 !leading-166 !text-mix-grey !py-2.5 !pr-1.5 ps-0 border-b border-solid border-mix-grey;
  }
  .row {
    @apply flex flex-wrap flex-row mx-[-12px];
  }

  .col-3 {
    @apply w-[25%] px-[12px];
  }

  .col-1 {
    @apply w-[8.33%] px-[12px];
  }

  .col-2 {
    @apply w-[16.67%] px-[12px];
  }

  .col-4 {
    @apply w-[33.33%] px-[12px];
  }

  .col-6 {
    @apply w-[50%] pl-[12px] pr-[12px];
  }

  .col-5 {
    @apply w-[41.65%] pl-[12px] pr-[12px];
  }

  .col-7 {
    @apply w-[58.31%] pl-[12px] pr-[12px];
  }

  .col-8 {
    @apply w-[66.64%] pl-[12px] pr-[12px];
  }

  .col-12 {
    @apply w-[100%] px-[12px];
  }
}

.cross span:nth-child(1) {
  transform: rotate(46deg) translateY(13px) translateX(3.5px);
}

.cross span:nth-child(2) {
  transform: translateX(-100%);
  opacity: 0;
}

.cross span:nth-child(3) {
  transform: rotate(-47deg) translateY(-13px) translateX(4px);
}
